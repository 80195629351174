import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const ConfirmEmail = Loadable(lazy(() => import('./ConfirmEmail')));
const Login = Loadable(lazy(() => import('./Login')));
const NotFound = Loadable(lazy(() => import('./NotFound')));
const ForgotPassword = Loadable(lazy(() => import('./ForgotPassword')));
const UnAuthorized = Loadable(lazy(() => import('./UnAuthorized')));

const sessionRoutes = [
	{ path: 'confirm/:userID/:confirmationCode', element: <ConfirmEmail /> },
	{ path: '/session/signin', element: <Login /> },
	{ path: '/session/forgot-password', element: <ForgotPassword /> },
	{ path: '/session/unauthorized', element: <UnAuthorized /> },
	{ path: '/session/404', element: <NotFound /> },
];

export default sessionRoutes;

import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const VehicleOwnerList = Loadable(lazy(() => import('./VehicleOwnerList')));
const VehicleOwnerForm = Loadable(lazy(() => import('./VehicleOwnerForm')));
const VehicleOwnerDetails = Loadable(lazy(() => import('./VehicleOwnerDetails')));

const vehicleOwnerRoutes = [
	{ path: '/vehicle-owner', element: <VehicleOwnerList />, auth: authRoles.staff },
	{ path: '/vehicle-owner/new', element: <VehicleOwnerForm />, auth: authRoles.staff },
	{ path: '/vehicle-owner/:id', element: <VehicleOwnerDetails />, auth: authRoles.staff },
	{ path: '/vehicle-owner/update/:id', element: <VehicleOwnerForm />, auth: authRoles.staff },
];

export default vehicleOwnerRoutes;
import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const DriverList = Loadable(lazy(() => import('./DriverList')));
const DriverForm = Loadable(lazy(() => import('./DriverForm')));
const DriverDetails = Loadable(lazy(() => import('./DriverDetails')));

const driverRoutes = [
	{ path: '/driver', element: <DriverList />, auth: authRoles.staff },
	{ path: '/driver/new', element: <DriverForm />, auth: authRoles.staff },
	{ path: '/driver/:id', element: <DriverDetails />, auth: authRoles.staff },
	{ path: '/driver/update/:id', element: <DriverForm />, auth: authRoles.staff },
];

export default driverRoutes;
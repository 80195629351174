const ROLES = {
	'SuperAdmin': 9534,
	'Admin': 6812,
	'Staff': 3256
}

export const authRoles = {
    sa: [ROLES.SuperAdmin], // Only Super Admin has access
    admin: [ROLES.SuperAdmin, ROLES.Admin], // Only Super Admin & Admin has access
    staff: [ROLES.SuperAdmin, ROLES.Admin, ROLES.Staff], // Only Super Admin & Admin & Staff has access
}

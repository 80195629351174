import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { logout, setCredentials } from '../features/auth/authSlice';
import { baseUrlDev, baseUrlPro } from './baseUrl';

const baseURL =
	window.location.hostname.includes("dev") || window.location.hostname.includes("localhost")
		? baseUrlDev
		: baseUrlPro;

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
    baseUrl: baseURL,
    credentials: 'include',
    timeout: 15000,
    prepareHeaders: (headers, {getState}) => {
        headers.set('Accept', 'application/json');
        headers.set('Cache-Control', 'no-cache');
        headers.set('Pragma', 'no-cache');
        headers.set('Expires', '0');

        const token = getState().auth.token || JSON.parse(localStorage.getItem("authenticatedUser"))?.accessToken;
        if (token) {
            headers.set("Authorization", `Bearer ${token}`)
        }
        return headers;
    }
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
    await mutex.waitForUnlock();
    let result = await baseQuery(args, api, extraOptions)

    if (result?.error?.originalStatus === 403) {
        if (!mutex.isLocked()) {
            const release = await mutex.acquire();

            try {
                console.log('sending refresh token');
                // send refresh token to get a new access token
                const refreshResult = await baseQuery('/auth/refresh', api, extraOptions);
                // console.log(refreshResult);

                if(refreshResult?.data) {
                    const email = api.getState().auth.email || JSON.parse(localStorage.getItem("authenticatedUser"))?.email;
                    const role = api.getState().auth.role || JSON.parse(localStorage.getItem("authenticatedUser"))?.role;
                    const name = api.getState().auth.name || JSON.parse(localStorage.getItem("authenticatedUser"))?.name;
                    // store the new token
                    api.dispatch(setCredentials({ 
                        accessToken: refreshResult.data.accessToken, 
                        email, 
                        role, 
                        name,
                        branch: refreshResult.data.branch, 
                        company: refreshResult.data.company
                    }));
                    // retry the original query with new access token
                    result = await baseQuery(args, api, extraOptions);
                } else {
                    await baseQuery('/auth/logout', api, extraOptions);
                    api.dispatch(logout());
                }
            } finally {
                release();
            }
        } else {
            await mutex.waitForUnlock();
            result = await baseQuery(args, api, extraOptions);
        }
    }
    return result;
}

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    tagTypes: [
        'Branch', 'Company', 'Customer', 'Driver', 'Parcel', 'ParcelTransaction', 
        'ParcelType', 'Staff', 'Town', 'TransactionChannel', 'User', 'VehicleOwner', 
        'Vehicle', 'VehicleType'
    ],
    refetchOnMountOrArgChange: 5,
    refetchOnFocus: true,
    endpoints: builder => ({})
})
import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const ParcelMovementList = Loadable(lazy(() => import('./ParcelMovementList')));
// const ParcelMovementForm = Loadable(lazy(() => import('./ParcelMovementForm')));
const DispatchToVehicle = Loadable(lazy(() => import('./DispatchToVehicle')));
const ReceiveFromVehicle = Loadable(lazy(() => import('./ReceiveFromVehicle')));
const DeliverToRecipient = Loadable(lazy(() => import('./DeliverToRecipient')));
const AssignedParcels = Loadable(lazy(() => import('./AssignedParcels')));

const parcelMovementRoutes = [
	{ path: '/parcel-movement', element: <ParcelMovementList />, auth: authRoles.staff },
    // { path: '/parcel-movement/new', element: <ParcelMovementForm />, auth: authRoles.staff },
    { path: '/parcel-movement/dispatch-to-vehicle', element: <DispatchToVehicle />, auth: authRoles.staff },
    { path: '/parcel-movement/receive-from-vehicle', element: <ReceiveFromVehicle />, auth: authRoles.staff },
    { path: '/parcel-movement/deliver-to-recipient', element: <DeliverToRecipient />, auth: authRoles.staff },
    { path: '/parcel-movement/assigned-parcels', element: <AssignedParcels />, auth: authRoles.staff },
];

export default parcelMovementRoutes;
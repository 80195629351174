import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const StaffList = Loadable(lazy(() => import('./StaffList')));
const StaffForm = Loadable(lazy(() => import('./StaffForm')));
const StaffDetails = Loadable(lazy(() => import('./StaffDetails')));

const staffRoutes = [
	{ path: '/staff', element: <StaffList />, auth: authRoles.admin },
	{ path: '/staff/new', element: <StaffForm />, auth: authRoles.admin },
	{ path: '/staff/:id', element: <StaffDetails />, auth: authRoles.admin },
];

export default staffRoutes;
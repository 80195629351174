import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const CompanyList = Loadable(lazy(() => import('./CompanyList')));
const CompanyForm = Loadable(lazy(() => import('./CompanyForm')));
const CompanyDetails = Loadable(lazy(() => import('./CompanyDetails')));

const companyRoutes = [
	{ path: '/company', element: <CompanyList />, auth: authRoles.sa },
	{ path: '/company/new', element: <CompanyForm />, auth: authRoles.sa },
	{ path: '/company/:id', element: <CompanyDetails />, auth: authRoles.sa },
	{ path: '/company/update/:id', element: <CompanyForm />, auth: authRoles.sa },
];

export default companyRoutes;